import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <p>
          My name is Caroline
        </p>
        <div>Hello</div>
    </div>
  );
}

export default App;
